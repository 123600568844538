.App {
    background-color: #f9f9f9;
    min-height: 95vh;
}

.card {
    background-color: white;
}

.row {
    margin-bottom: 0em
}