.API-news {
    margin: 0.2em auto;
}

.article-page {
    margin: 3em auto;
    margin-bottom: 0em;
}

.article-title {
    font-weight: bold;
}

.article-body:hover {
    outline: solid 1px black;
}