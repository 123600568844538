.footer {
    text-align: center;
    margin: auto;
    background-color: #f9f9f9
}

.footer>p {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0
}