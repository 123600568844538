.portfolio-page {
    width: 85vw;
    margin: 1em auto;
    margin-bottom: 0em;
}

.portfolio_logo {
    position: sticky;
    max-width: 220px;
    height: 220px;
    margin: 1em auto;
    box-shadow: 0.3em 0.5em 2em grey;
    top: 4em;
    border-radius: 50%;
}

.API-news {
    margin-top: 0.2em auto;
}

.portfolio-card {
    margin-top: 0.5em;
    margin-bottom: 2em;
}

.wired-card_width {
    width: 100%
}

@media screen and (max-width:990px) {
    .portoflio-page {
        width: 90vw;
    }
    .portfolio_logo {
        position: relative;
        top: 0em;
    }
}